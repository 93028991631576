import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { closeAppModal, openAppModal } from '../../store/app'
import { MODALS } from '../../store/app/types'
import IndividualInfoModal from './IndividualInfoModal'
import NewOfferModal from './NewOfferModal'
import RedirectAitargetModal from './RedirectAitargetModal'
import selector from './selector'

export interface IModalProps {
  onClose: () => void
}

const AppModals = () => {
  const { modals } = useSelector(selector)
  const search = useLocation().search

  const dispatch = useDispatch()

  useEffect(() => {
    const isRedirect = new URLSearchParams(search).get('from_to')

    if (isRedirect) {
      dispatch(openAppModal({ name: MODALS.REDIRECT_AITARGET }))
    }
  }, [])

  const onClose = () => dispatch(closeAppModal())

  switch (modals?.name) {
    case MODALS.NEW_OFFER: {
      return <NewOfferModal onClose={onClose} />
    }
    case MODALS.REDIRECT_AITARGET: {
      return <RedirectAitargetModal onClose={onClose} />
    }
    case MODALS.INDIVIDUAL_INFO: {
      return <IndividualInfoModal onClose={onClose} />
    }
    default:
      return null
  }
}

export default AppModals
