import React, { ReactNode } from 'react'

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export type IEventFunc = (event: React.FormEvent<HTMLInputElement>) => void

export type IChangeFunc = (event: React.ChangeEvent<HTMLInputElement>) => void

export enum AgencyDocsReceivedStatus {
  EMPTY = 'empty',
  SENT = 'sent',
  APPROVED = 'approved',
}

export enum CloseDocTypes {
  PAYMENT = 'payment',
  SPEND = 'spend',
}

export interface IDecodedJWT {
  exp: number
  jti: string
  token_type: string
  user_id: number
}

export interface IFormField {
  hasError: boolean
  errorText: string
  touched: boolean
  value: string
}

export interface ISingleCheckboxField {
  hasError: boolean
  errorText: string
  touched: boolean
  value: boolean
}

export interface IDocumentInfo {
  description: string
  name: string
}

export interface IDocumentsList {
  [key: string]: {
    base: IDocumentInfo[]
    extra?: IDocumentInfo[]
  }
}

export interface ISuggestCompany {
  value: string
  unrestricted_value: string
  data: {
    kpp: string
    capital: null
    management: {
      name: string
      post: string
    }
    founders: string[]
    managers: string[]
    branch_type: 'MAIN' | 'BRANCH'
    branch_count: number
    source: null
    qc: null
    hid: string
    type: 'LEGAL' | 'INDIVIDUAL'
    state: {
      status: 'ACTIVE' | 'LIQUIDATING' | 'LIQUIDATED' | 'REORGANIZING'
      actuality_date: number
      registration_date: number
      liquidation_date: number
    }
    opf: {
      type: string
      code: string
      full: string
      short: string
    }
    name: {
      full_with_opf: string
      short_with_opf: string
      latin: string
      full: string
      short: string
    }
    inn: string
    ogrn: string
    okpo: null
    okved: string
    okveds: string
    authorities: null
    documents: null
    licenses: null
    address: {
      value: string
      unrestricted_value: string
      data: {
        source: string
        qc: 0 | 1 | 3
      }
    }
    phones: null
    emails: null
    ogrn_date: number
    okved_type: string
  }
}

export type InvoiceStatus = 'issued' | 'partially_payed' | 'partially_processed' | 'payed' | 'processed'

export interface IPromocode {
  code: string
  terms: string
}

export type NetworkTypes = 'facebook' | 'google' | 'vk' | 'mytarget' | 'tiktok' | 'yandex'

export type NetworkExtraTypes = NetworkTypes | 'avito' | 'telegram' | 'vkads' | 'yandex_promo_page' | 'vk_adsmarket'

export type LinkNetworkTypes = 'two_gis'

export enum CarrotQuestEvents {
  AUTH = '$authorized',
  INVOICE = 'Выставил счет',
  TELEGRAM_ADS_START_FILL_REQUEST = 'Начал заполнять заявку Telegram Ads',
  TELEGRAM_ADS_END_FILL_REQUEST = 'Завершил заполнение формы Telegram Ads',
  VK_ADS_MARKET_START_FILL_REQUEST = 'Начал заполнять заявку VK Ads Market',
  VK_ADS_MARKET_END_FILL_REQUEST = 'Завершил заполнение формы VK Ads Market',
  YANDEX_PROMO_ADS_START_FILL_REQUEST = 'Начал заполнять заявку YandexPromo',
  YANDEX_PROMO_PAGE_ADS_END_FILL_REQUEST = 'Завершил заполнение формы YandexPromo',
}

export enum CarrotQuestProps {
  FIRST_ACCOUNT = 'Дата создания первого аккаунта',
}

export enum Pages {
  ALL = 'all',
  FIRST = 1,
}

export enum GroupingTabValues {
  PLATFORM = 'platform',
  CLIENT = 'client',
}

export enum Currencies {
  RUB = 'RUB',
  EUR = 'EUR',
}

export interface ISelectItem {
  label: string | JSX.Element
  value: string
  icon?: ReactNode
  testId?: string
}

export enum BenefitsRange {
  MORE_0 = '0 – 30 000',
  MORE_30_000 = '30 000 – 100 000',
  MORE_100_000 = '100 000 – 200 000',
  MORE_200_000 = '200 000 – 1 000 000',
  MORE_1_000_000 = '1 000 000 – 3 000 000',
  MORE_3_000_000 = '3 000 000 +',
}

export type YandexPercentage = {
  [key in keyof typeof BenefitsRange]: Percentage
}

export const enum Percentage {
  FOUR = '4%',
  FIVE = '5%',
  SIX = '6%',
}

export interface IBenefit {
  id: number
  values: string[]
  label: string | null
  extraLabel?: string | null
  title: ReactNode
}

export enum Platforms {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  MYTARGET = 'mytarget',
  TIKTOK = 'tiktok',
  VK = 'vk',
  VK_ADS = 'vkads',
  VK_ADS_MARKET = 'vk_adsmarket',
  YANDEX = 'yandex',
  AVITO = 'avito',
  YANDEX_BUSINESS = 'yandex_business',
  ARCHIVED_ACCOUNTS = 'archived_accounts',
  TELEGRAM = 'telegram',
  YANDEX_PROMO_PAGE = 'yandex_promo_page',
  TWO_GIS = 'two_gis',
}

const Currency = Object.values(Currencies)

export type DelimitersType = SpaceDelimiter | typeof Currency
export type SpaceDelimiter = [' ', ',']

export enum DocumentMethodTypes {
  POST = 'post',
  OFFICE = 'office',
  DIADOC = 'diadoc',
  UNNECESSARY = 'unnecessary',
}

export enum Industries {
  OTHERS = 'others',
  REAL_ESTATE = 'real_estate',
  EDUCATION = 'education',
  CRYPTOCURRENCIES = 'cryptocurrencies',
  MTS_PRO_BIG_DATA = 'mts_pro_big_data',
}

export enum AGENCY_STATUS {
  BUSINESS = 'business',
  IP = 'ip',
  INDIVIDUAL = 'individual',
  SELF_EMPLOYED = 'self_employed',
}

export const enum PARTNERS {
  MG_COM = 'MGCom',
  SA = 'SA',
  MAGNETTO = 'Magnetto',
  MTS = 'MTS',
}

export enum LANGUAGE {
  RUSSIAN = 'ru',
}
