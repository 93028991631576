import * as Sentry from '@sentry/browser'
import { AxiosError } from 'axios'

import customAxios from './axios'
import enhancedError from './enhanceError'
import * as jwt from './jwt'

const addPrefixNull = (num: number) => (num < 10 ? `0${num}` : num)
const bodySelector = document.querySelector('body')
let scrollPosition = 0

export const getDate = (dateString: string) => {
  const date = new Date(dateString)

  return `${date.getFullYear()}-${addPrefixNull(date.getMonth() + 1)}-${addPrefixNull(date.getDate())}`
}

export const getDateString = (dateString: string) => {
  const date = new Date(dateString)

  return `${addPrefixNull(date.getDate())}.${addPrefixNull(date.getMonth() + 1)}.${date.getFullYear()}`
}

export const getDateWithMonth = (dateString: string, hasYear = true, hasNull = true, hasDay = true) => {
  const date = new Date(dateString)
  const month = date.toLocaleString('ru-RU', { month: 'long' })
  const formattedDate = date.getDate()
  const result = `${hasDay ? (hasNull ? addPrefixNull(formattedDate) : formattedDate) : ''} ${month.substring(0, 3)}`

  return hasYear ? `${result} ${date.getFullYear()}` : result
}

export const getDateWithWeekday = (dateString: string) => {
  const date = new Date(dateString)
  const options = { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit' }
  const formattedDate = date.toLocaleDateString('ru-RU', options as any)

  // Need slice extra string ' .г' and capitalize first-letter
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1, -3)
}

export const getMonthFromDatestring = (dateString: string, hasYear = true) => {
  const date = new Date(dateString)
  const options = hasYear ? { year: 'numeric', month: 'long' } : { month: 'long' }
  const formattedDate = date.toLocaleDateString('ru-RU', options as any)

  // Need slice extra string ' .г' and capitalize first-letter
  return hasYear
    ? formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1, -3).split(' ').join(', ')
    : capitalizeFirstLetter(formattedDate)
}

export const capitalizeFirstLetter = (str: string) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '')

export const baseCatchError = (error: AxiosError) => {
  Sentry.configureScope((scope: Sentry.Scope) => {
    scope.setExtra('response', error.response)
  })

  return Promise.reject(error)
}

export const getDateWithTimeFromDateString = (dateString: string) => {
  const date = new Date(dateString)
  const reportYear = date.getFullYear()
  const currentYear = new Date().getFullYear()
  const options = { day: '2-digit', month: 'long', hour: '2-digit', minute: '2-digit' }
  const optionsWithYear = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
  const formattedDate = date.toLocaleDateString(
    'ru-RU',
    reportYear === currentYear ? (options as any) : (optionsWithYear as any)
  )

  return formattedDate.replace(/ г./, '')
}

export const getFullDateFromDateString = (dateString: string, isShortYear = true) => {
  const date = new Date(dateString)
  const options = { day: '2-digit', month: 'long', year: isShortYear ? '2-digit' : 'numeric' }
  const formattedDate = date.toLocaleDateString('ru-RU', options as any)

  // Need slice extra string ' .г' and capitalize first-letter
  return formattedDate.slice(0, -3).replace(/^0?/, '')
}

export const getHoursFromDateString = (dateString: string) => {
  const date = new Date(dateString)
  const options = { hour: '2-digit', minute: '2-digit' }
  return date.toLocaleTimeString('ru-RU', options as any)
}

export const getFullStringDateFromDateString = (dateString: string, isShortYear = true) => {
  const date = new Date(dateString)
  const options = { day: '2-digit', month: '2-digit', year: isShortYear ? '2-digit' : 'numeric' }
  return date.toLocaleDateString('ru-RU', options as any)
}

export const getYearFromDateString = (dateString: string, isShortYear = false) => {
  const date = new Date(dateString)
  const options = { year: isShortYear ? '2-digit' : 'numeric' }
  return date.toLocaleDateString('ru-RU', options as any)
}

export const getQuarterFromDateString = (dateString: string) => {
  const date = new Date(dateString)
  const options = { month: '2-digit' }
  const month = date.toLocaleDateString('ru-RU', options as any)

  const quarters: { [key: string]: string[] } = {
    '1': ['01', '02', '03'],
    '2': ['04', '05', '06'],
    '3': ['07', '08', '09'],
    '4': ['10', '11', '12'],
  }

  const quarter = Object.keys(quarters).find((key: string) => quarters[key].includes(month))

  return `Q${quarter || ''}`
}

// output format: пн, 4 сен 2023
export const getStringDateWithWeekday = (dateString: string) => {
  const date = new Date(dateString)
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
  const formattedDate = date.toLocaleDateString('ru-RU', options as any)

  // Need slice extra string ' .г' and capitalize first-letter
  return formattedDate.charAt(0) + formattedDate.slice(1, -3)
}

export const disableBodyScroll = () => {
  scrollPosition = window.pageYOffset

  if (bodySelector) {
    bodySelector.style.overflow = 'hidden'
    bodySelector.style.position = 'fixed'
    bodySelector.style.top = `-${scrollPosition}px`
    bodySelector.style.width = '100%'
  }
}

export const enableBodyScroll = (hasScrolling = true) => {
  if (bodySelector) {
    bodySelector.style.removeProperty('overflow')
    bodySelector.style.removeProperty('position')
    bodySelector.style.removeProperty('top')
    bodySelector.style.removeProperty('width')

    if (hasScrolling) {
      window.scrollTo(0, scrollPosition)
    }
  }
}

export { enhancedError, jwt, customAxios }

export const removeEmptyFields = (obj: { [key: string]: any }): { [key: string]: any } =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.fromEntries(Object.entries(obj).filter(([_key, value]) => !!value))

export const getWordByNumber = (number: number, words: string[]) => {
  const value = Math.abs(number) % 100
  const rest = value % 10

  if (value > 10 && value < 20) return words[2]
  if (rest > 1 && rest < 5) return words[1]
  if (rest == 1) return words[0]
  return words[2]
}

export const getNumberFromString = (value: string): number => Number(value.replace(/\D/g, ''))
