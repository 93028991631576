export enum AppActionTypes {
  SET_LOADED = 'SET_LOADED',
  SET_MODAL = 'SET_MODAL',
  TOGGLE_MENU = 'TOGGLE_MENU',
  TOGGLE_FINANCE_MENU = 'TOGGLE_FINANCE_MENU',
  TOGGLE_TOOL_MENU = 'TOGGLE_TOOL_MENU',
  TOGGLE_ANALYTICS_MENU = 'TOGGLE_ANALYTICS_MENU',
}

export enum MODALS {
  NEW_OFFER = 'newOffer',
  TELEGRAM = 'telegram',
  REDIRECT_AITARGET = 'redirectAitarget',
  INDIVIDUAL_INFO = 'individualInfo',
}

export interface IModal {
  name: MODALS
  data?: any
}

export interface IAppState {
  loaded: boolean
  isShowedModal: boolean
  isMenuOpened: boolean
  isFinanceMenuOpened: boolean
  isToolMenuOpened: boolean
  isAnalyticsMenuOpened: boolean
  modals: IModal | null
}
