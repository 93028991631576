import { Reducer } from 'redux'

import { BudgetActionTypes, IBalance } from './types'

export const initialState: IBalance[] = []

const reducer: Reducer<IBalance[]> = (state = initialState, action: any) => {
  switch (action.type) {
    case BudgetActionTypes.SET_ALL_BALANCES: {
      return [...action.data]
    }
    default: {
      return state
    }
  }
}

export { reducer as budgetReducer }
