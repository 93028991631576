import { IAccountsCardProps } from './components/Cards/types'
import { MODALS } from './modals/types'

export enum AccountsFilterTypes {
  CONTRACT = 'contract',
  ADPLATFORM = 'ad_platform',
  NAME = 'name',
}

export interface IProfileAdmarkInfo {
  has_not_phone_numbers: boolean | null
}

export interface IModal {
  name: MODALS
  data?: any
}

export interface IAccountsFilled {
  account_filled: number
  account_total: number
}

export interface IAccountsListType {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  loading: boolean
  results: IAccountsCardProps[]
}

export interface IAccountsState {
  accountsList: IAccountsListType
  accountsSelectedList: IAccountsListType
  filterQuery: string
  accountsFilled: IAccountsFilled
  modals: IModal | null
  profileAdmarkInfo: IProfileAdmarkInfo
}

export enum AccountsActionTypes {
  SET_ACCOUNTS = 'ADS_MARKING_SET_ACCOUNTS',
  UPDATE_ACCOUNTS = 'ADS_MARKING_UPDATE_ACCOUNTS',
  SET_ACCOUNTS_LOADING = 'ADS_MARKING_SET_ACCOUNTS_LOADING',
  SET_ACCOUNTS_FILTER_QUERY = 'ADS_MARKING_SET_ACCOUNTS_FILTER_QUERY',
  CLEAR_ACCOUNTS = 'ADS_MARKING_CLEAR_ACCOUNTS',
  SET_ACCOUNTS_FILLED = 'ADS_MARKING_SET_ACCOUNTS_FILLED',
  SET_ACCOUNTS_PROFILE_ADMARK_INFO = 'ADS_MARKING_SET_ACCOUNTS_PROFILE_ADMARK_INFO',
}

export enum AccountsFormsActionTypes {
  SET_ACCOUNTS_SELECTED = 'ADS_MARKING_SET_ACCOUNTS_SELECTED',
  SET_ACCOUNTS_SELECTED_LOADING = 'ADS_MARKING_SET_ACCOUNTS_SELECTED_LOADING',
}
