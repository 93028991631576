import chroma from 'chroma-js'
import hexToRgba from 'hex-to-rgba'

import { darken, lighten } from '../common/colorUtils'

export const getColorLighten = (color: string, percent: number) => lighten(chroma(color), percent / 100).hex()

export const PurpleBase = '#603AC7'
export const PurpleLighten46 = lighten(chroma(PurpleBase), 0.46).hex() // '#F3F1FB'
export const PurpleLighten43 = lighten(chroma(PurpleBase), 0.43).hex() // '#EAE5F8'
export const PurpleLighten40 = lighten(chroma(PurpleBase), 0.4).hex() // '#E0D9F4'
export const PurpleLighten25 = lighten(chroma(PurpleBase), 0.25).hex() // '#B09DE3'
export const PurpleLighten12 = lighten(chroma(PurpleBase), 0.12).hex() // '#866AD5'
export const PurpleLighten6 = lighten(chroma(PurpleBase), 0.06).hex() // '#7352CE'
export const PurpleDarken4 = darken(chroma(PurpleBase), 0.04).hex() // '#5834B8'
export const PurpleDarken8 = darken(chroma(PurpleBase), 0.08).hex() // '#5030A8'

export const GreenBase = '#70C24B'
export const GreenLighten41 = lighten(chroma(GreenBase), 0.41).hex() // '#ECF7E7'
export const GreenLighten33 = lighten(chroma(GreenBase), 0.33).hex() // '#D4EDC9'
export const GreenLighten25 = lighten(chroma(GreenBase), 0.25).hex() // '#BCE2AA'
export const GreenLighten12 = lighten(chroma(GreenBase), 0.12).hex() // '#94D179'
export const GreenDarken4 = darken(chroma(GreenBase), 0.04).hex() // '#65BA3F'
export const GreenDarken9 = darken(chroma(GreenBase), 0.09).hex() // '#5BA738'
export const GreenDarken22 = darken(chroma(GreenBase), 0.22).hex() // '#407528'

export const RedBase = '#F54C5A'
export const RedLighten34 = lighten(chroma(RedBase), 0.34).hex()
export const RedLighten33 = lighten(chroma(RedBase), 0.33).hex() // '#FEEBED'
export const RedLighten30 = lighten(chroma(RedBase), 0.3).hex() // '#FDDDE0'
export const RedLighten28 = lighten(chroma(RedBase), 0.28).hex() // '#FDD3D7'
export const RedLighten20 = lighten(chroma(RedBase), 0.2).hex() // '#FAADB3'
export const RedLighten14 = lighten(chroma(RedBase), 0.14).hex() // '#F99098'
export const RedDarken4 = darken(chroma(RedBase), 0.04).hex() // '#F43948'
export const RedDarken8 = darken(chroma(RedBase), 0.08).hex() // '#F32536'
export const RedDarken16 = darken(chroma(RedBase), 0.16).hex() // '#E30D1E'

export const PinkBase = '#DF51A3'
export const PinkLighten36 = lighten(chroma(PinkBase), 0.36).hex() // '#FCECF5'
export const PinkLighten34 = lighten(chroma(PinkBase), 0.34).hex() // '#FAE3F0'
export const PinkLighten32 = lighten(chroma(PinkBase), 0.32).hex() // '#F8DBEC'
export const PinkLighten31 = lighten(chroma(PinkBase), 0.31).hex() // '#F8D7EA'
export const PinkLighten25 = lighten(chroma(PinkBase), 0.25).hex() // '#F3BDDC'
export const PinkLighten15 = lighten(chroma(PinkBase), 0.15).hex() // '#EB92C5'

export const OrangeBase = '#F4A72D'
export const OrangeSecondary = '#FBA93F'
export const OrangeLighten37 = lighten(chroma(OrangeBase), 0.37).hex() // '#FDF2E0'
export const OrangeLighten31 = lighten(chroma(OrangeBase), 0.31).hex() // '#FCE6C3'
export const OrangeLighten23 = lighten(chroma(OrangeBase), 0.23).hex() // '#FAD69C'
export const OrangeLighten11 = lighten(chroma(OrangeBase), 0.11).hex() // '#F7BD62'
export const OrangeDarken29 = darken(chroma(OrangeBase), 0.29).hex() // '#865507'
export const OrangeDarken8 = darken(chroma(OrangeBase), 0.08).hex() // '#EC950C'
export const OrangeDarken4 = darken(chroma(OrangeBase), 0.04).hex() // '#F39F1A'

export const YellowBase = '#FFD334'
export const YellowLighten30 = lighten(chroma(YellowBase), 0.3).hex() // '#FFF4CD'
export const YellowLighten24 = lighten(chroma(YellowBase), 0.24).hex() // '#FFEEAE'
export const YellowLighten15 = lighten(chroma(YellowBase), 0.15).hex() // '#FFE480'
export const YellowDarken20 = darken(chroma(YellowBase), 0.2).hex() // '#CDA100'
export const YellowDarken35 = darken(chroma(YellowBase), 0.35).hex() // '#806500'

// Text
export const TextGreyBase = '#2E2E2E'
export const TextGreyLighten39 = lighten(chroma(TextGreyBase), 0.39).hex() // '#919191'
export const TextGreyLighten25 = lighten(chroma(TextGreyBase), 0.25).hex() // '#6E6E6E'
export const TextGreyDarken7 = darken(chroma(TextGreyBase), 0.07).hex() // '#1C1C1C'

export const TextSlateGreyBase = '#717B88'
export const TextSlateGreyLighten30 = lighten(chroma(TextSlateGreyBase), 0.3).hex() // '#C4C8CE'
export const TextSlateGreyLighten16 = lighten(chroma(TextSlateGreyBase), 0.16).hex() // '#9DA4AE'
export const TextSlateGreyLighten8 = lighten(chroma(TextSlateGreyBase), 0.08).hex() // '#87909B'
export const TextSlateGreyDarken12 = darken(chroma(TextSlateGreyBase), 0.12).hex() // '#555D67'
export const TextSlateGreyDarken20 = darken(chroma(TextSlateGreyBase), 0.2).hex() // '#434950'
export const TextSlateGreyDarken7 = darken(chroma(TextSlateGreyBase), 0.7).hex() // '#616974'

// GreyShades
export const GreyBase = '#2E2E2E'
export const GreyLighten77 = lighten(chroma(GreyBase), 0.77).hex() // '#F2F2F2'
export const GreyLighten74 = lighten(chroma(GreyBase), 0.74).hex() // '#EBEBEB'
export const GreyLighten69 = lighten(chroma(GreyBase), 0.69).hex() // '#DEDEDE'
export const GreyLighten60 = lighten(chroma(GreyBase), 0.6).hex() // '#C7C7C7'
export const GreyLighten47 = lighten(chroma(GreyBase), 0.47).hex() // '#A6A6A6'
export const GreyLighten26 = lighten(chroma(GreyBase), 0.26).hex() // '#707070'

export const SlateGreyBase = '#667387'
export const SlateGreyLighten50 = lighten(chroma(SlateGreyBase), 0.5).hex() // '#F5F6F7'
export const SlateGreyLighten49 = lighten(chroma(SlateGreyBase), 0.49).hex() // '#F2F3F5'
export const SlateGreyLighten46 = lighten(chroma(SlateGreyBase), 0.46).hex() // '#E9EBEE'
export const SlateGreyLighten43 = lighten(chroma(SlateGreyBase), 0.43).hex() // '#E0E3E8'
export const SlateGreyLighten39 = lighten(chroma(SlateGreyBase), 0.39).hex() // '#D5D9DF'
export const SlateGreyLighten34 = lighten(chroma(SlateGreyBase), 0.34).hex() // '#C6CCD4'
export const SlateGreyLighten22 = lighten(chroma(SlateGreyBase), 0.22).hex() // '#A3ACBA'
export const SlateGreyLighten13 = lighten(chroma(SlateGreyBase), 0.13).hex() // '#8995A6'

// Background
export const BackgroundGrey = '#F0F3F7'
export const BackgroundWhite = '#FFFFFF'

// Black and White
export const Black = '#000000'
export const White = '#FFFFFF'

export const Transparent = 'transparent'

// old colors
export const blackClrShadow = hexToRgba('#000', 0.06)
export const blackClrOpacity = hexToRgba('#000', 0.8)
export const blackClrLightest = hexToRgba('#9a97d2', 0.2)
export const blackClrLightOpacity = hexToRgba('#2e2e2e', 0.1)
export const blue2Clr = hexToRgba('#8593a4')
export const blue3Clr = hexToRgba('#98a6bc')
export const secondaryClr = hexToRgba('#6d6d6d')
export const brandClrLight = hexToRgba('#8e41fd')
export const brandClr = hexToRgba('#7d25fb')
export const purple2Clr = hexToRgba('#7c4de5')
export const brandClrDarkPurple = hexToRgba('#3e127a')
export const brandClrDark = hexToRgba('#6617d8')
export const brandClrDarkest = hexToRgba('#393857')
export const brandClrLighter = hexToRgba('#9488BD')
export const brandClrLightest = hexToRgba('#f5f5fc')
export const brandClrGradient = hexToRgba('#45b9fc')
export const lemonClr = hexToRgba('#eee449')
export const pinkHoverClr = hexToRgba('#ffdbf0')
export const pinkFocusClr = hexToRgba('#f8d4e8')
export const pinkClrOpacity = hexToRgba('#df51a3', 0.2)
export const pinkClrDark = hexToRgba('#c13a88')
export const pinkClrLight = hexToRgba('#ffe7ec')
export const pinkClrLightest = hexToRgba('#ffe3f3')
export const redClr = hexToRgba('#ff1c53')
export const roseClrDark = hexToRgba('#ff2a64')
export const anthraciteClr = hexToRgba('#192026')
export const purpleClr = hexToRgba('#7556b0')
export const purpleClrLight = hexToRgba('#9a97e6')
export const graphiteClr = hexToRgba('#262626')
export const greyClrDark = hexToRgba('#323c47')
export const grey5Clr = hexToRgba('#e0e0e0')
export const greyClrOpacity = hexToRgba('#919191', 0.2)
export const greyClr = hexToRgba('#42505c')
export const greyClrBlue = hexToRgba('#607793')
export const greyClrBlueLight = hexToRgba('#a1b2c7')
export const greyClrBlueLightest = hexToRgba('#a1b2c7', 0.1)
export const greyClrLoader = hexToRgba('#f0f3f7', 0.5)
export const greyClrModal = hexToRgba('#607793', 0.8)
export const greyClrHeaderShadow = hexToRgba('#292840', 0.2)
export const greyClrLight = hexToRgba('#e2ecf2')
export const greySky2Clr = hexToRgba('#8595a7')
export const greySky3Clr = hexToRgba('#a3abb9')
export const greySky5Clr = hexToRgba('#d3dbe6')
export const greySky6Clr = hexToRgba('#dbe2e9')
export const greySky7Clr = hexToRgba('#e6ebef')
export const greyShades7Clr = hexToRgba('#f3f3f3')
export const greyClrSilver = hexToRgba('#c4c4c4')
export const greyClrSilverLightest = hexToRgba('#c4c4c4', 0.2)
export const greyClrSlate = hexToRgba('#fafafa')
export const greyClrMarengo = hexToRgba('#dadce0')
export const greyClrMarengoLight = hexToRgba('#dadce0', 0.5)
export const greyClrLava = hexToRgba('#c6c9d0')
export const greyClrEmpty = hexToRgba('#e4e6eb')
export const whiteClrTransparent = hexToRgba('#ffffff', 0.2)
export const orangeClr = hexToRgba('#D39F73')
export const orangeClrLight = hexToRgba('#FDF6F0')
export const orangeClrDark = hexToRgba('#FBA93F')
export const blueClrLight = hexToRgba('#a5afbf')
export const blueClrLightest = hexToRgba('#c1c9d6')
export const redClrDarkest = 'rgba(255, 0, 0, 0.3)'
export const redClrDark = 'rgba(255, 0, 0, 0.6)'
export const greenClr = hexToRgba('#a8d87b')
export const greenDarkClr = hexToRgba('#11ad37')
export const greenDarkClrOpacity = hexToRgba('#11ad37', 0.2)
export const tableCellBg = hexToRgba('#f9fbfc')
export const greyClrLabel = hexToRgba('#6f7072')
export const footerBg = hexToRgba('#010e28')
export const headerClrLight = hexToRgba('#3b3a5a')
export const headerClrDark = hexToRgba('#814ef0')
export const purpleClrLighter = hexToRgba('#c6b6eb')
export const purpleClrLightest = hexToRgba('#f4ecff')
export const purpleClrLightestOpacity = hexToRgba('#f4ecff', 0.2)
export const violetClr = hexToRgba('#6c63ff')
export const turquoiseClr = hexToRgba('#1895e8')
export const greenClrBright = hexToRgba('#0ac738')
export const greenClrBrightOpacity = hexToRgba('#0ac738', 0.2)
export const greenClrBrightest = hexToRgba('#0ac738', 0.8)
export const greenClrLight = hexToRgba('#ebfff2')
export const greenClrDark = hexToRgba('#6eb982')
export const redClrLight = hexToRgba('#eb5c49')
export const redClrLightest = hexToRgba('#eb5c49', 0.2)
export const scrollbarClr = hexToRgba('#c4cbd2', 0.55)
export const spacePurple5Clr = hexToRgba('#f5f1ff')
export const spacePurple4Clr = hexToRgba('#e3d8fc')
export const spacePurple1Clr = hexToRgba('#e3d6f4')
export const inputBorderBg = hexToRgba('#d9dcde')
