import { Reducer } from 'redux'

import { IPollsState, PollsActionTypes } from './types'

const initialList = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  results: [],
  loading: false,
}

const initialState: IPollsState = {
  pollsList: initialList,
  userVotesList: initialList,
}

const reducer: Reducer<IPollsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case PollsActionTypes.SET_POLLS: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        pollsList: {
          ...listParams,
          loading: false,
          results: [...state.pollsList.results, ...results],
        },
      }
    }

    case PollsActionTypes.SET_VOTES: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        userVotesList: {
          ...listParams,
          loading: false,
          results: [...state.userVotesList.results, ...results],
        },
      }
    }

    default: {
      return state
    }
  }
}

export { reducer as pollsReducer }
