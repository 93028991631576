export enum PollsActionTypes {
  SET_POLLS = 'SET_POLLS',
  SET_VOTES = 'SET_VOTES',
}

export enum PollTypes {
  WHY_REFUND = 'why_refund',
  HAS_YANDEX_PROMO_PAGE = 'has_yandex_promo_page',
  HAS_VK_ADS_MARKET = 'has_vk_adsmarket',
  HAS_YANDEX = 'has_yandex',
  HAS_VKADS = 'has_vkads',
  HAS_AVITO = 'has_avito',
  HAS_TELEGRAM = 'has_telegram',
  HAS_MYTARGET = 'has_mytarget',
  HAS_VK = 'has_vk',
}

export interface IPollsState {
  pollsList: IPollsList
  userVotesList: IUserVotesList
}

interface IChioce {
  id: number
  poll: number
  choice_text: string
}

export interface IPoll {
  title: string
  slug: PollTypes
  is_published: boolean
  id: number
  description: string
  choices: IChioce[]
}

export interface IUserVotes {
  id: number
  poll: number
  choice: number
  other_text: string
  platform: string
  profile: number
}

export interface IUserVotesList {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  loading: boolean
  results: IUserVotes[]
}

export interface IPollsList {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  loading: boolean
  results: IPoll[]
}

export interface IVoteBody {
  poll: number
  choice: number
  other_text: string
  profile: number | null
  platform: string
}
