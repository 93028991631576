import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { IAccountRequest, RequestsActionTypes } from './types'

export function getRequests(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/additional_accounts_requests/requests_list/`, 'GET', null, true)
      .then((response: AxiosResponse<IAccountRequest[]>) => {
        dispatch({ type: RequestsActionTypes.SET_REQUESTS, data: response.data, network: 'facebook' })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
