import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { BudgetActionTypes, IBalancesState, IChargeInfo, IStatusCheck, PlatformStatusChecks } from './types'

export function getAllBalances(clientId?: string): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    const url = clientId ? `${API_URL}/profile_agency/client_balances/${clientId}/` : `${API_URL}/all_balances/`

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<IBalancesState>) => {
        dispatch({ type: BudgetActionTypes.SET_ALL_BALANCES, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getChargeInfo(accountFrom: string, accountTo: string): AxiosPromise<IChargeInfo> {
  return customAxios(
    `${API_URL}/get_charge_info/?account_from=${accountFrom}&account_to=${accountTo}`,
    'GET',
    null,
    true
  )
    .then((response: AxiosResponse<IChargeInfo>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function transferAmount(
  accountFrom: string,
  accountTo: string,
  amount: number,
  clientId?: string
): AxiosPromise<any> {
  const url = clientId ? `${API_URL}/transfer/${clientId}/` : `${API_URL}/transfer/`

  return customAxios(url, 'POST', { account_from: accountFrom, account_to: accountTo, amount }, true)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getAccountStatus(platform: PlatformStatusChecks, accountId: string): AxiosPromise<IStatusCheck> {
  return customAxios(`${API_URL}/profile/account/${platform}/${accountId}/`, 'GET', null, true)
    .then((response: AxiosResponse<IStatusCheck>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}
