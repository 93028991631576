import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { IModalProps } from '../AppModals'
import selector from './selector'
import useStyles from './styles'
import { IndividualInfoFormData, IndividualInfoInputs } from './types'

import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '@/components/ButtonSMB'
import { Asterisk } from '@/components/Icons'
import { Input } from '@/components/InputSMB'
import { Modal } from '@/components/ModalSMB'
import { MAX_INN_LENGTH } from '@/const'
import { PurpleBase } from '@/const/colors'
import { Routes } from '@/const/routes'
import { closeAppModal } from '@/store/app'
import { MODALS } from '@/store/app/types'
import { updateProfile } from '@/store/user'

export type FormFields = 'inn' | 'name'

const IndividualInfoModal = (props: IModalProps) => {
  const { onClose } = props

  const { profile } = useSelector(selector)
  const dispatch = useDispatch()

  const { formatMessage } = useIntl()

  const isBalancePage = useLocation().pathname === Routes.BALANCE

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    shouldFocusError: false,
    defaultValues: {
      inn: profile.inn || '',
      name: profile.name || '',
    },
  })

  const classes = useStyles()

  const cancelHandler = useCallback(() => onClose(), [onClose])

  const onSubmit = (data: IndividualInfoFormData) => {
    const { inn, name } = data

    dispatch(updateProfile({ name, inn }))
    dispatch(closeAppModal({ name: MODALS.INDIVIDUAL_INFO }))
  }

  const nameChangeHandler = (fieldName: FormFields, value: string) => {
    setValue(fieldName, value.replace(/[0-9]/g, ''))
  }

  const innChangeHandler = (fieldName: FormFields, value: string) => {
    setValue(fieldName, value.replace(/[^0-9]/g, ''))
  }

  return (
    <Modal
      isOpen={true}
      closeCallback={cancelHandler.bind(this, 'cross')}
      headerContent={formatMessage({ id: 'app.modal.individualInfo.title' })}
      bodyContent={
        <div>
          {isBalancePage && (
            <div className={classes.balance_info}>
              <FormattedMessage id="app.modal.individualInfo.balance_info" />
            </div>
          )}

          <div className={classes.form}>
            <div>
              <div className={classes.label}>
                <FormattedMessage id="app.modal.individualInfo.fio_label" />{' '}
                <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
              </div>
              <Controller
                name={IndividualInfoInputs.name}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={formatMessage({ id: 'app.modal.individualInfo.fio_placeholder' })}
                    fullWidth
                    error={!!errors.name}
                    onChange={(e) => nameChangeHandler(IndividualInfoInputs.name, e.target.value)}
                  />
                )}
              />
            </div>
            <div>
              <div className={classes.label}>
                <FormattedMessage id="app.modal.individualInfo.inn_label" />{' '}
                <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
              </div>
              <Controller
                name={IndividualInfoInputs.inn}
                control={control}
                rules={{ required: true, maxLength: MAX_INN_LENGTH, minLength: MAX_INN_LENGTH }}
                render={({ field }) => (
                  <Input
                    {...field}
                    maxLength={MAX_INN_LENGTH}
                    placeholder={formatMessage({ id: 'app.modal.individualInfo.inn_placeholder' })}
                    fullWidth
                    error={!!errors.inn}
                    onChange={(e) => innChangeHandler(IndividualInfoInputs.inn, e.target.value)}
                  />
                )}
              />
              <div>
                {errors.inn?.type === 'minLength' && (
                  <div className={classes.errorText}>
                    <FormattedMessage id="app.modal.individualInfo.inn_error" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      footerContent={
        <div className={classes.footer}>
          <Button
            label={formatMessage({ id: 'app.modal.individualInfo.btn_cancel' })}
            size={BUTTON_SIZE.size40}
            variant={BUTTON_VARIANT.secondaryPurple}
            onClick={cancelHandler}
          />
          <Button
            label={formatMessage({ id: 'common.save' })}
            size={BUTTON_SIZE.size40}
            variant={BUTTON_VARIANT.primary}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      }
      maxWidth="460px"
    />
  )
}

export default IndividualInfoModal
