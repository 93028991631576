import { AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { IVoteBody, PollsActionTypes } from './types'

export const getAvailablePolls = () => {
  return async (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/polls/`
    try {
      const response: AxiosResponse<any> = await customAxios(url, 'GET', null, true)
      dispatch({ type: PollsActionTypes.SET_POLLS, data: response.data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const getPollsById = (id: string) => {
  return async (): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/polls/${id}`
    try {
      const response: AxiosResponse<any> = await customAxios(url, 'GET', null, true)
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const getUserVotes = () => {
  return async (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/polls/votes/`
    try {
      const response: AxiosResponse<any> = await customAxios(url, 'GET', null, true)
      dispatch({ type: PollsActionTypes.SET_VOTES, data: response.data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const createVotes = (body: IVoteBody) => {
  return async (): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/polls/votes/`
    try {
      const response: AxiosResponse<any> = await customAxios(url, 'POST', body, true)
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
