import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import selector from './selector'
import useStyles from './styles'

import { Mixpanel } from '@/analytics'
import { getNumWithDelimiter } from '@/common/utils'
import { Delimiters, LINK_CASHBACK_PERSONAL } from '@/const'
import { Routes } from '@/const/routes'

const Cashback = () => {
  const classes = useStyles()
  const { cashback, isCashbackEnabled } = useSelector(selector)

  const onMyCashbackClick = () => {
    Mixpanel.track('HeaderGoToMyCashback')
  }

  if (!cashback) {
    return null
  }

  if (!isCashbackEnabled) {
    return (
      <a className={classes.linkWrapper} href={LINK_CASHBACK_PERSONAL} rel="noopener noreferrer" target="_blank">
        <FormattedMessage id="app.cashback.link" />
      </a>
    )
  }

  return (
    <Link className={classes.linkWrapper} to={Routes.MY_CASHBACK} onClick={onMyCashbackClick}>
      <FormattedMessage id="app.cashbackPerMonth.link" values={{ month: cashback.month }} />
      &nbsp;
      <span>{getNumWithDelimiter(cashback.amount, Delimiters.SPACE)}&nbsp;&#8381;</span>
    </Link>
  )
}

export default Cashback
