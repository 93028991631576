import { createSelector } from 'reselect'

import IApplicationState from '../../../types/state'

import { getAdsMarkingAccount, getBrowser, getProfile } from '@/common/selectors'
import { AGENCY } from '@/const'

export const adsMarkingSelector = createSelector(
  [getProfile, getAdsMarkingAccount, getBrowser],
  (profile, accounts, browser) => ({
    isPayingCustomer: profile.paying_customer,
    shouldStartProductTour:
      !profile.ads_marking_product_tour && !!accounts.accountsList.results && browser.greaterThan.medium,
    profileAdmarkInfo: accounts.profileAdmarkInfo,
  })
)

export const shouldRenderAdsMarking = (state: IApplicationState) =>
  state.user.profile.industry === AGENCY && state.user.profile.partner_status && !state.user.profile.is_profile_agency
