export enum RequestsActionTypes {
  SET_REQUESTS = 'SET_REQUESTS',
}

export interface IRequestsState {
  facebook: IAccountRequest[]
}

export interface IAccountRequest {
  website: string
  industry: number
  subindustry: number
}
